import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { CodeBlock, dracula } from "react-code-blocks"
import "./style.css"

const BlogPost = slug => {
  const data = slug.data.markdownRemark
  const htmlCode = slug.data.markdownRemark.html
  const jsCode = data.frontmatter.js

  return (
    <Layout>
      <div
        className="blogsContainer blog_container"
        style={{ marginTop: "50px" }}
      >
        <div key={data.id}>
          <h2>{data.frontmatter.name}</h2>
          <p className="date">{data.frontmatter.date}</p>
          {/* {data.frontmatter.slug} */}
          <img
            src={`/blogImages/${data.frontmatter.img}`}
            alt={data.frontmatter.slug}
          />
          <h3>HTML & CSS:</h3>
          <CodeBlock
            text={htmlCode}
            language="html"
            showLineNumbers="true"
            wrapLines
            theme={dracula}
          />
          <br />
          {jsCode !== "NoCode" ? (
            <>
              <h3>JS Code:</h3>
              <CodeBlock
                text={jsCode}
                language="css"
                showLineNumbers="true"
                wrapLines
                theme={dracula}
              />
              <br />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query ($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        date
        img
        name
        slug
        title
        js
      }
      html
    }
  }
`
